
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "de",
  "nl-be"
]

export const localeLoaders = {
  nl: [
    {
      key: "locale_nl_46json_5822633f",
      load: () => import("#nuxt-i18n/5822633f" /* webpackChunkName: "locale_nl_46json_5822633f" */),
      cache: true
    },
    {
      key: "locale_nl_46json_591803d6",
      load: () => import("#nuxt-i18n/591803d6" /* webpackChunkName: "locale_nl_46json_591803d6" */),
      cache: true
    },
    {
      key: "locale_nl_46json_5822633f",
      load: () => import("#nuxt-i18n/5822633f" /* webpackChunkName: "locale_nl_46json_5822633f" */),
      cache: true
    },
    {
      key: "locale_nl_46json_591803d6",
      load: () => import("#nuxt-i18n/591803d6" /* webpackChunkName: "locale_nl_46json_591803d6" */),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_46json_e8e74a5c",
      load: () => import("#nuxt-i18n/e8e74a5c" /* webpackChunkName: "locale_de_46json_e8e74a5c" */),
      cache: true
    },
    {
      key: "locale_de_46json_aa68679d",
      load: () => import("#nuxt-i18n/aa68679d" /* webpackChunkName: "locale_de_46json_aa68679d" */),
      cache: true
    },
    {
      key: "locale_de_46json_e8e74a5c",
      load: () => import("#nuxt-i18n/e8e74a5c" /* webpackChunkName: "locale_de_46json_e8e74a5c" */),
      cache: true
    },
    {
      key: "locale_de_46json_aa68679d",
      load: () => import("#nuxt-i18n/aa68679d" /* webpackChunkName: "locale_de_46json_aa68679d" */),
      cache: true
    }
  ],
  "nl-be": [
    {
      key: "locale_nl_46json_5822633f",
      load: () => import("#nuxt-i18n/5822633f" /* webpackChunkName: "locale_nl_46json_5822633f" */),
      cache: true
    },
    {
      key: "locale_nl_45be_46json_8246dc06",
      load: () => import("#nuxt-i18n/8246dc06" /* webpackChunkName: "locale_nl_45be_46json_8246dc06" */),
      cache: true
    },
    {
      key: "locale_nl_46json_591803d6",
      load: () => import("#nuxt-i18n/591803d6" /* webpackChunkName: "locale_nl_46json_591803d6" */),
      cache: true
    },
    {
      key: "locale_nl_45be_46json_bd951e2f",
      load: () => import("#nuxt-i18n/bd951e2f" /* webpackChunkName: "locale_nl_45be_46json_bd951e2f" */),
      cache: true
    },
    {
      key: "locale_nl_46json_5822633f",
      load: () => import("#nuxt-i18n/5822633f" /* webpackChunkName: "locale_nl_46json_5822633f" */),
      cache: true
    },
    {
      key: "locale_nl_45be_46json_8246dc06",
      load: () => import("#nuxt-i18n/8246dc06" /* webpackChunkName: "locale_nl_45be_46json_8246dc06" */),
      cache: true
    },
    {
      key: "locale_nl_46json_591803d6",
      load: () => import("#nuxt-i18n/591803d6" /* webpackChunkName: "locale_nl_46json_591803d6" */),
      cache: true
    },
    {
      key: "locale_nl_45be_46json_bd951e2f",
      load: () => import("#nuxt-i18n/bd951e2f" /* webpackChunkName: "locale_nl_45be_46json_bd951e2f" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/e0153761" /* webpackChunkName: "config_i18n_46config_46ts_e0153761" */),
  () => import("#nuxt-i18n/f4404f69" /* webpackChunkName: "config_i18n_46config_46ts_f4404f69" */),
  () => import("#nuxt-i18n/d97ee07b" /* webpackChunkName: "config_i18n_46config_46ts_d97ee07b" */),
  () => import("#nuxt-i18n/20b18797" /* webpackChunkName: "config_i18n_46config_46ts_20b18797" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: false
  },
  compilation: {
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "nl",
      language: "nl-NL",
      storeCode: "tra_nl_nl",
      domain: "https://www.travelbags.nl",
      name: "NL",
      flag: "🇳🇱",
      numberFormats: {
        currency: {
          style: "currency",
          currency: "EUR"
        }
      },
      datetimeFormats: {
        default: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        },
        short: {
          year: "numeric",
          month: "short",
          day: "2-digit"
        },
        long: {
          year: "numeric",
          month: "long",
          day: "2-digit",
          weekday: "long"
        }
      },
      files: [
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl.json"
      ]
    },
    {
      code: "de",
      language: "de-DE",
      storeCode: "tra_de_de",
      domain: "https://www.travelbags.de",
      name: "DE",
      flag: "🇩🇪",
      numberFormats: {
        currency: {
          style: "currency",
          currency: "EUR"
        }
      },
      datetimeFormats: {
        default: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        },
        short: {
          year: "numeric",
          month: "short",
          day: "2-digit"
        },
        long: {
          year: "numeric",
          month: "long",
          day: "2-digit",
          weekday: "long"
        }
      },
      files: [
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/de.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/locales/de.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/de.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/locales/de.json"
      ]
    },
    {
      code: "nl-be",
      language: "nl-BE",
      storeCode: "tra_be_nl",
      domain: "https://www.travelbags.be",
      files: [
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl-be.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl-be.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl-be.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl-be.json"
      ],
      name: "BE",
      flag: "🇧🇪",
      numberFormats: {
        currency: {
          style: "currency",
          currency: "EUR"
        }
      },
      datetimeFormats: {
        default: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        },
        short: {
          year: "numeric",
          month: "short",
          day: "2-digit"
        },
        long: {
          year: "numeric",
          month: "long",
          day: "2-digit",
          weekday: "long"
        }
      }
    }
  ],
  defaultLocale: "nl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: true,
  langDir: "i18n/locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.travelbags.nl",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "nl",
    language: "nl-NL",
    storeCode: "tra_nl_nl",
    domain: "https://www.travelbags.nl",
    name: "NL",
    flag: "🇳🇱",
    numberFormats: {
      currency: {
        style: "currency",
        currency: "EUR"
      }
    },
    datetimeFormats: {
      default: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      },
      short: {
        year: "numeric",
        month: "short",
        day: "2-digit"
      },
      long: {
        year: "numeric",
        month: "long",
        day: "2-digit",
        weekday: "long"
      }
    },
    files: [
      {
        path: "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl.json",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    language: "de-DE",
    storeCode: "tra_de_de",
    domain: "https://www.travelbags.de",
    name: "DE",
    flag: "🇩🇪",
    numberFormats: {
      currency: {
        style: "currency",
        currency: "EUR"
      }
    },
    datetimeFormats: {
      default: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      },
      short: {
        year: "numeric",
        month: "short",
        day: "2-digit"
      },
      long: {
        year: "numeric",
        month: "long",
        day: "2-digit",
        weekday: "long"
      }
    },
    files: [
      {
        path: "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/de.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/src/tra/i18n/locales/de.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/de.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/src/tra/i18n/locales/de.json",
        cache: undefined
      }
    ]
  },
  {
    code: "nl-be",
    language: "nl-BE",
    storeCode: "tra_be_nl",
    domain: "https://www.travelbags.be",
    files: [
      {
        path: "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl-be.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl-be.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/locales/nl-be.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl.json",
        cache: undefined
      },
      {
        path: "/builds/tra/webshop/frontend/src/tra/i18n/locales/nl-be.json",
        cache: undefined
      }
    ],
    name: "BE",
    flag: "🇧🇪",
    numberFormats: {
      currency: {
        style: "currency",
        currency: "EUR"
      }
    },
    datetimeFormats: {
      default: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      },
      short: {
        year: "numeric",
        month: "short",
        day: "2-digit"
      },
      long: {
        year: "numeric",
        month: "long",
        day: "2-digit",
        weekday: "long"
      }
    }
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/