
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _126cmsk0ZiZjvoF_pEYqDVv2N1qRZEY7rSOSqQJI_X1w2rdYIMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/~cms.vue?macro=true";
import { default as _126plpLQOS_FPdw2VffmqBU8tGIZVKs7S6Uw_qngYyPzCmqC8Meta } from "/builds/tra/webshop/frontend/src/tra/app/pages/~plp.vue?macro=true";
import { default as cartgekJEtyeGcb8jD8xU_0dMmDu3gKAja246DAzFvFpXMQMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/cart.vue?macro=true";
import { default as indexTOT3vu2UKocADhpeAaQymgHq9zY7sfYi27HWq0C7bQUMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/index.vue?macro=true";
import { default as searchcJbVxKl6rp3uT9dw0J4SBNNaz7YTkOCqUID2jyOdU_45gMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/search.vue?macro=true";
import { default as editycG17fUp6TOy5ilU7rJojy635jk40LdzQrnTdtOubUsMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/edit.vue?macro=true";
import { default as indextn_kzYARdOjW7qe3LGebkMl2ERCIgPafVXn_5WVuLuwMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/index.vue?macro=true";
import { default as newsletterjJck4zOqKeO5AcE1SjrsnvhgJOK0Rb6JhWClgP8MwV8Meta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/newsletter.vue?macro=true";
import { default as _91id_93EyLUthXHLca2luS1gMnX_45Wt_hIM1gtcj0MlM1c1XL1oMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/orders/[id].vue?macro=true";
import { default as indexxDW3GvxvcPYrK1rKEHi7cVcyb0GjrDwmeOJmFDN7wKUMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/orders/index.vue?macro=true";
import { default as indexa8LFMYqLXgWhNjIRfKprpJahTt3_45Joi8HuJL9kY2H9cMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/index.vue?macro=true";
import { default as createe152Es8pPTtck8xtmLpij7JuBoHotTInrhOBtehwwTYMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/create.vue?macro=true";
import { default as _91id_93BGvC5qk_450tGqVewPtrHoFHXQ8cwz7hhisr4f1ZTwgvoMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/edit/[id].vue?macro=true";
import { default as accountmaxqXEbxYABKWHvEEW2zD9yCnjZ8qFrJL1a3_45p_6_45eoMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/account.vue?macro=true";
import { default as previewOfSMGfbvQuHJOKLFUO5zVgblGmVoH47JGzNtjG48PU4Meta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-strapi/src/app/pages/preview.vue?macro=true";
import { default as successfFzKyENxvMoucJz6257eBNRzEgAN2bLe1XDPgiINAQ4Meta } from "/builds/tra/webshop/frontend/src/tra/app/pages/success.vue?macro=true";
import { default as indexDaU0O8Nsjfuc6LjrHhTkqAiG8EBeHWggcykztKKwshwMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/checkout/index.vue?macro=true";
import { default as payment9PWpqP73unIf9jsqgJaipPzGHcgdkz5pzIbUuehU_LQMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/checkout/payment.vue?macro=true";
import { default as shipment1pZHG7sT9_3_45Pa7_V4EbDWunbjwKBpEl05R5XAkcAhEMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/checkout/shipment.vue?macro=true";
import { default as checkoutu46wZCk7N_45bbmil2CLV33_5KTIUapucKO1hwg_45cM9IcMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/checkout.vue?macro=true";
import { default as registerAMs6KcOJKBS79Q6D_QdnadnU2COEnKsBcwBxz_tqdTgMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/register.vue?macro=true";
import { default as wishlist9eV9OTjR_B7ujrMv28_2NdipC_sLUjAS3CQk0epEHY0Meta } from "/builds/tra/webshop/frontend/src/tra/app/pages/wishlist.vue?macro=true";
import { default as _91_46_46_46slug_93wY5uo5Ulhm_45FyLI0Wgf0NRXPB9NK1PU7Nmdb4Kd6nkQMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/[...slug].vue?macro=true";
import { default as _126cms_45columnsCxPXijV70I0ZnCiIPCTu8SWL9MkpchWbcwPikyOl65EMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-strapi/src/app/pages/~cms-columns.vue?macro=true";
import { default as kioskOTyP6IiE515v5lNyb_45GkCUgZ0Kxqk83_45AHjWyXsRf3IMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/config/kiosk.vue?macro=true";
import { default as empty_45layouttg3OamqgiFb66EOcBFw2o_333KGsqsVro1f6pQvRfBoMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/pages/empty-layout.vue?macro=true";
import { default as reset_45passwordf77wprqtfIwDXSppIl_Wc4mK_NeME_45B2I6vBNlKLN6EMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/reset-password.vue?macro=true";
import { default as create_45passwordKcyqdH9hMK7_esMTuxWlzp_45tgdP88bf1lGi7E1cPTdcMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/create-password.vue?macro=true";
import { default as forgot_45passwordQ25VmJGEFUU0JNPd5i7q_45vZdDiLSjzqQ018_457w8SH54Meta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/forgot-password.vue?macro=true";
export default [
  {
    name: "~cms",
    path: "/~cms",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/~cms.vue")
  },
  {
    name: "~plp",
    path: "/~plp",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/~plp.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/cart.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/search.vue")
  },
  {
    name: accountmaxqXEbxYABKWHvEEW2zD9yCnjZ8qFrJL1a3_45p_6_45eoMeta?.name,
    path: "/account",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/account.vue"),
    children: [
  {
    name: "account-edit",
    path: "edit",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/edit.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/index.vue")
  },
  {
    name: "account-newsletter",
    path: "newsletter",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/newsletter.vue")
  },
  {
    name: "account-orders-id",
    path: "orders/:id()",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/orders/index.vue")
  },
  {
    name: "account-addresses",
    path: "addresses",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/index.vue")
  },
  {
    name: "account-addresses-create",
    path: "addresses/create",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/create.vue")
  },
  {
    name: "account-addresses-edit-id",
    path: "addresses/edit/:id()",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/edit/[id].vue")
  }
]
  },
  {
    name: "preview",
    path: "/preview",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-strapi/src/app/pages/preview.vue")
  },
  {
    name: "success",
    path: "/success",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/success.vue")
  },
  {
    name: checkoutu46wZCk7N_45bbmil2CLV33_5KTIUapucKO1hwg_45cM9IcMeta?.name,
    path: "/checkout",
    meta: checkoutu46wZCk7N_45bbmil2CLV33_5KTIUapucKO1hwg_45cM9IcMeta || {},
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/checkout.vue"),
    children: [
  {
    name: "checkout",
    path: "",
    meta: indexDaU0O8Nsjfuc6LjrHhTkqAiG8EBeHWggcykztKKwshwMeta || {},
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment",
    path: "payment",
    meta: payment9PWpqP73unIf9jsqgJaipPzGHcgdkz5pzIbUuehU_LQMeta || {},
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/checkout/payment.vue")
  },
  {
    name: "checkout-shipment",
    path: "shipment",
    meta: shipment1pZHG7sT9_3_45Pa7_V4EbDWunbjwKBpEl05R5XAkcAhEMeta || {},
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/checkout/shipment.vue")
  }
]
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/register.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/wishlist.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/[...slug].vue")
  },
  {
    name: "~cms-columns",
    path: "/~cms-columns",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-strapi/src/app/pages/~cms-columns.vue")
  },
  {
    name: "config-kiosk",
    path: "/config/kiosk",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/config/kiosk.vue")
  },
  {
    name: "empty-layout",
    path: "/empty-layout",
    meta: empty_45layouttg3OamqgiFb66EOcBFw2o_333KGsqsVro1f6pQvRfBoMeta || {},
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/pages/empty-layout.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/reset-password.vue")
  },
  {
    name: "create-password",
    path: "/create-password",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/create-password.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/forgot-password.vue")
  }
]