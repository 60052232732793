export function useSanitizeUrl(url: string, includeDomain = false) {
  if (!url) {
    return ''
  }

  // Return hash urls
  if (['http://#', 'https://#'].includes(url)) {
    return '#'
  }

  const { public: config } = useRuntimeConfig()
  const { productionUrl, baseDomain } = config

  let objUrl
  // if (URL.canParse(url)) { // iOS17
  //   objUrl = new URL(url)
  // }
  // else if (URL.canParse(url, productionUrl)) { // iOS17
  //   objUrl = new URL(url, productionUrl)
  // }
  // else {
  //   console.error(`Can not parse url: ${url}`)
  //   return url
  // }

  try {
    objUrl = new URL(url)
    // console.log('objUrl1', objUrl)
  }
  catch {
    try {
      objUrl = new URL(`${productionUrl}/${url}`)
      // console.log('objUrl2', objUrl)
    }
    catch { /* */ }
  }

  if (!objUrl) {
    console.error(`Can not parse url: ${url}`)
    return url
  }

  // Return non http/https urls
  if (!['http:', 'https:'].includes(objUrl?.protocol)) {
    // console.log('Non http/https urls', url)
    return url
  }

  // Return external urls
  if (![new URL(productionUrl).origin, new URL(baseDomain).origin].includes(objUrl.origin)) {
    // console.log('External url', url)
    return url
  }

  // Force lowercase urls
  objUrl.pathname = objUrl.pathname.toLowerCase()

  // Fix trailing slashes
  if (!objUrl.pathname.endsWith('/') && !objUrl.pathname.endsWith('.html')) {
    // console.log('add trailing slash to', objUrl.pathname)
    objUrl.pathname = (objUrl.pathname + '/')
  }

  // Fix .html with / combo
  if (objUrl.pathname.endsWith('.html/')) {
    objUrl.pathname = objUrl.pathname.substring(0, objUrl.pathname.length - 1)
  }

  // Fix double slashes
  if (objUrl.pathname.includes('//')) {
    // console.log('remove double slash from', objUrl.pathname)
    objUrl.pathname = objUrl.pathname.replace(/\/+/g, '/')
  }

  // Return full url
  if (includeDomain) {
    return objUrl.toString()
  }

  // Return 'internal' url
  return objUrl.pathname + objUrl.search + objUrl.hash
}
