
          import defu from 'defu'
          import { plugin, defaultConfig, createInput } from '@formkit/vue'
          import { nl, de } from '@formkit/i18n'

          // Todo:
          // - map against existing translations available in formkit
          // - check if files exists

          import nlCustom from '~layers/i18n/locales/formkit/nl.ts'
import deCustom from '~layers/i18n/locales/formkit/de.ts'

          import { FormKitToggle, FormKitRating } from '#components'

          import formkitConf from '~layers/formkit.config.ts'

          const formkitConfig = formkitConf() || {}

          export default defineNuxtPlugin((nuxtApp) => {

            nuxtApp.vueApp.use(
              plugin,
              defaultConfig(
                defu(formkitConfig, {
                  locale: 'nl',
                  locales: {
                    'nl': defu(nlCustom, nl), 'de': defu(deCustom, de)
                  },
                  inputs: {
                    toggle: createInput(FormKitToggle), rating: createInput(FormKitRating),
                  },
                })
              )
            )
          })
        