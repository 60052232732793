import revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8 from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_PwBbnTvlRNAAeYsss_ut8_F1UYa84ux_WGDmOfEjemM from "/builds/tra/webshop/frontend/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8 from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34 from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0 from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_mPo2CnG9qXxcpAumQCb3eLJlZCUP2PiwUIlzz_GVZjI from "/builds/tra/webshop/frontend/src/tra/.nuxt-nl/components.plugin.mjs";
import prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_SckK337ikqEbKybg3hp2_Ieo82VCbVmB2_tXjsduv4w from "/builds/tra/webshop/frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_Coz7Zg_6RoNzMpUn0HKZf0d22eAYAJNwpieeg_yoc0Q from "/builds/tra/webshop/frontend/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import formkit_fields_zAwhgO_KgVjRgggtBWKThAX_hWnTpNjinVP0GYYzygY from "/builds/tra/webshop/frontend/src/tra/.nuxt-nl/formkit-fields.ts";
import recaptcha_srqxhYrO4NLSRosDWgu8n3rI5JIYAuV_NFCKmz7NOdE from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/modules/10_recaptcha/runtime/plugins/recaptcha.ts";
import switch_locale_path_ssr_Q4QG_JWrFkldguQIG03zqcO_kK_4AUpve2dv3MvQ_1I from "/builds/tra/webshop/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_XKVBybqs_BXvMN1URnuJp0XGMyBFmJaJQkYoeau32jM from "/builds/tra/webshop/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_19v21r6K1zdMR19WY1buFv7wSXagWynDr1PPElFzQrU from "/builds/tra/webshop/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_soD_TqwMxuSJ5sU0YtPzqeNvoYHF3ceTxG8CF2lxJOk from "/builds/tra/webshop/frontend/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import apollo_errors_d7AKyKxcMrt_rT2EIryh6FBGxx3IqSF3dgdZLTmoWPM from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/plugins/apollo-errors.ts";
import apollo_persisted_queries_k_1S8TM_VvLLUzLLx3dET5EDrTQgaQmAXCss0cQz9O0 from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/plugins/apollo-persisted-queries.ts";
import apollo_recaptcha_1WcSTf0z4gint6pDGBTwRZXPdQlfXMPfJadX_EvlyKg from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/plugins/apollo-recaptcha.ts";
import interpolation_xIBk38fQ0rXXwRBkS9twbPEv1ej0IyEATSc9bzj_awk from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/plugins/interpolation.ts";
import ssg_detect_JQT9b_sVERYDW__eN0wuiqDH6nql0nVptMbJ3IvFy8E from "/builds/tra/webshop/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY,
  unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c,
  router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8,
  _0_siteConfig_PwBbnTvlRNAAeYsss_ut8_F1UYa84ux_WGDmOfEjemM,
  payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8,
  navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34,
  check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw,
  chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0,
  components_plugin_mPo2CnG9qXxcpAumQCb3eLJlZCUP2PiwUIlzz_GVZjI,
  prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c,
  plugin_SckK337ikqEbKybg3hp2_Ieo82VCbVmB2_tXjsduv4w,
  i18n_Coz7Zg_6RoNzMpUn0HKZf0d22eAYAJNwpieeg_yoc0Q,
  formkit_fields_zAwhgO_KgVjRgggtBWKThAX_hWnTpNjinVP0GYYzygY,
  recaptcha_srqxhYrO4NLSRosDWgu8n3rI5JIYAuV_NFCKmz7NOdE,
  switch_locale_path_ssr_Q4QG_JWrFkldguQIG03zqcO_kK_4AUpve2dv3MvQ_1I,
  route_locale_detect_XKVBybqs_BXvMN1URnuJp0XGMyBFmJaJQkYoeau32jM,
  i18n_19v21r6K1zdMR19WY1buFv7wSXagWynDr1PPElFzQrU,
  plugin_soD_TqwMxuSJ5sU0YtPzqeNvoYHF3ceTxG8CF2lxJOk,
  apollo_errors_d7AKyKxcMrt_rT2EIryh6FBGxx3IqSF3dgdZLTmoWPM,
  apollo_persisted_queries_k_1S8TM_VvLLUzLLx3dET5EDrTQgaQmAXCss0cQz9O0,
  apollo_recaptcha_1WcSTf0z4gint6pDGBTwRZXPdQlfXMPfJadX_EvlyKg,
  interpolation_xIBk38fQ0rXXwRBkS9twbPEv1ej0IyEATSc9bzj_awk,
  ssg_detect_JQT9b_sVERYDW__eN0wuiqDH6nql0nVptMbJ3IvFy8E
]