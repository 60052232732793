import esUrlQuery from '~layers/app/graphql/routes/esUrlQuery.gql'
import esUrlsGlobalQuery from '~layers/app/graphql/routes/esUrlsGlobal.gql'

export function useCustomRouter() {
  const routeData = useState<any>('routeData', () => null)

  const router = useRouter()
  const { client } = useApolloClient('default')

  const isNuxtRoute = (path: string): boolean => {
    const nuxtPath = path.replace(/\/+$/g, '') // strip trailing slashes
    // console.log({ path, nuxtPath })

    return !!router.getRoutes().find((nuxtRoute: any) => nuxtRoute.path === nuxtPath)
  }

  const fetchRouteAlternate = async (routeId: string) => {
    const globalEsUrlResult = await client.query<any>({
      query: esUrlsGlobalQuery,
      variables: { id: routeId },
    })

    return globalEsUrlResult?.data?.esUrlsGlobal?.urls || []
  }

  const fetchRoute = async (path: string) => {
    const esPath = path.replace(/^\/+|\/+$/g, '') // strip leading and trailing slashes
    // console.log({ path, esPath })

    const esUrlResult = await client.query<any>({
      query: esUrlQuery,
      variables: { url: esPath },
    })

    const esUrl = esUrlResult?.data?.esUrl?.url // setting type to EsUrlResult gives issues when assigning to meta
    // console.log({ esUrl })

    if (esUrl?.page_type) {
      const globalUrls = await fetchRouteAlternate(esUrl?.id)
      // console.log({ globalUrls })

      routeData.value = {
        ...esUrl,
        global: globalUrls,
      }

      return routeData.value
    }

    return null
  }

  const { fetchRedirect } = useCustomRouterRedirect()

  const getUrlObject = () => {
    const config = useRuntimeConfig()?.public

    const request = useRequestEvent()?.node?.req

    const headers: any = request?.rawHeaders?.reduce((obj, item, index) => {
      if (index % 2 === 0) {
        obj[item.toLowerCase()] = request.rawHeaders[index + 1]
      }
      return obj
    }, {}) || {}

    const protocol = headers?.['x-forwarded-proto'] || location?.protocol?.replace(':', '') || 'https'
    const host = headers?.['host'] || location?.host
    let fullPath = request?.originalUrl || (location.pathname + location.search)

    fullPath = fullPath.replace('[object%20Object]', '')

    const basePath = config.baseUrl?.replace(config.baseDomain, '')?.replace(/\/+$/, '') || ''

    let [path, params] = fullPath.split('?')
    path = path.replace(basePath, '')
    params = params ? `?${params}` : ''

    const originalUrl = {
      protocol,
      host,
      origin: protocol + '://' + host,
      basePath,
      path,
      params,
      fullPath,
      url: `${protocol}://${host}${fullPath}`,
    }

    const [protocol2, host2] = config.baseDomain.split('://')
    const fullPath2 = useSanitizeUrl(fullPath)

    let path2 = useSanitizeUrl(path)
    path2 = path2.replace(basePath, '')

    // console.log({ fullPath2, path2 })

    const optimizedUrl = {
      protocol: protocol2,
      host: host2,
      origin: protocol2 + '://' + host2,
      basePath,
      path: path2,
      params,
      fullPath: fullPath2,
      url: `${protocol2}://${host2}${fullPath2}`,
    }

    return {
      originalUrl: {
        ...originalUrl,
      },
      optimizedUrl: {
        ...optimizedUrl,
      },
    }
  }

  return {
    isNuxtRoute,
    fetchRoute,
    routeData,
    fetchRedirect,
    getUrlObject,
  }
}
